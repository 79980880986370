import { Box, Typography, Container } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import IsMounted from "src/components/IsMounted";
import Loading from "src/components/Loading";
import { useParams } from "react-router-dom";
import http from "src/utils/http";
import PageNotFound from "src/components/PageNotFound";
import SL from "src/assets/images/survey-thank.png";
import PowerdBy from "../PowerdBy";
import Constant from "src/utils/Constant";
import { useTranslation } from "react-i18next";

const Thankyou = () => {
  const { surveyId, surveySlug } = useParams();
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const mounted = IsMounted();
  // const navigate = useNavigate();
  const { t } = useTranslation();

  const getSurveyDetails = useCallback(() => {
    http
      .get("/api/general/survey/details", {
        params: {
          survey_id: surveyId,
          slug: surveySlug,
        },
      })
      .then((response) => {
        if (mounted.current) {
          setDetails(response.data.data);
        }
      })
      .catch((err) => console.log("error:", err.message))
      .finally(() => setIsLoading(false));
  }, [mounted, surveyId, surveySlug]);

  useEffect(() => {
    getSurveyDetails();
  }, [getSurveyDetails]);

  if (isLoading) {
    return <Loading />;
  }

  if (!details) {
    return <PageNotFound />;
  }

  let organization =
    details?.coach_details?.organizations.length > 0
      ? details?.coach_details?.organizations[0]
      : null;

  let settings = organization?.settings
    ? JSON.parse(organization?.settings)
    : null;

  return (
    <Box>
      <Box
        sx={{
          minHeight: "calc(100vh - 70px)", // 70px is the height og poweredby component
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container
          maxWidth={"tp"}
          sx={{
            padding: "0px !important",
          }}
        >
          <Box
            sx={{
              mt: "60px",
            }}
          >
            {settings?.logo && (
              <Box
                sx={{
                  mb: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="Treffas logo"
                  src={Constant.getFileInStorage(
                    "coach/business",
                    settings?.logo
                  )}
                  // src={Constant.imageUrl("coach/business", settings?.logo)}
                  style={{
                    height: "100px",
                  }}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "740px",
              height: "320px",
              border: "1px solid #D9DBE9",
              borderRadius: "8px",
            }}
          >
            <Box>
              <Box textAlign={"center"}>
                <img
                  src={SL}
                  alt={"treffas survey thank you logo"}
                  style={{
                    width: "88.71px",
                    height: "88px",
                  }}
                />
              </Box>

              <Box>
                <Typography
                  sx={{
                    // fontSize: "18px",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    fontSize: "22px",
                    lineHeight: "31px",
                    color: "#060A2D",
                    textAlign: "center",
                  }}
                >
                  {t("survey.take.form.alert.thank_you")}
                </Typography>
              </Box>

              <Box mt={"7px"}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "center",
                  }}
                >
                  {details?.thankyou_message}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* powered by */}
      <Box>
        <PowerdBy />
      </Box>
    </Box>
  );
};

export default Thankyou;
