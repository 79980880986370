import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import BgFooter from "src/assets/images/bg-footer.png";
import TreffasLogo from "src/assets/images/treffas_logo.png";

const TreffasBanner = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: { xs: "100%", sm: "100px" },
        width: "100%",
        backgroundImage: `url(${BgFooter})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: { xs: "column", sm: "row" },
        p: "24px",
        gap: "24px",
      }}
    >
      <Box>
        <img src={TreffasLogo} alt="Treffas Logo" style={{ height: "25px" }} />
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "24px",
          }}
        >
          {" "}
          Make scheduling your next <br /> meeting easy with Treffas!{" "}
        </Typography>
      </Box>

      <Box>
        <Button
          variant="contained"
          sx={{ borderRadius: "6px", minWidth: "40px" }}
          onClick={() => navigate("/register")}
        >
          {" "}
          Start for free{" "}
        </Button>
      </Box>
    </Box>
  );
};

export default TreffasBanner;
