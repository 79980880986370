import { Avatar, Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import http from "src/utils/http";
import LoadingScreen from "src/components/LoadingScreen";
import Page from "src/components/Page";
import Constant from "src/utils/Constant";
import moment from "moment";
import DescTooltip from "../schedule/booking-header/DescTooltip";
import IsMounted from "src/components/IsMounted";
import PowerdBy from "../../PowerdBy";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ThankyouPage = () => {
  const { t } = useTranslation();
  const [client, setClient] = useState(null);
  const [coachDetails, setCoachDetails] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = useQuery();
  const clientId = searchParams.get("client_id");
  const appointmentId = searchParams.get("appointment_id");
  const organizationId = searchParams.get("organization_id");
  const userId = searchParams.get("user_id");
  const mounted = IsMounted();

  const getClientDetails = useCallback(() => {
    http
      .get("/api/general/individual/coach/client-details", {
        params: {
          client_id: clientId,
        },
      })
      .then((response) => {
        if (mounted.current) {
          setClient(response.data);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));
  }, [clientId, mounted]);

  const getCoachDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/coach-details", {
        params: {
          user_id: userId,
          organization_id: organizationId,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setCoachDetails(data);
        }
      })
      .catch((err) => console.log(err.message));
  }, [userId, organizationId, mounted]);

  const getAppointmentDetails = useCallback(() => {
    http
      .get(`/api/general/individual/appointment/details`, {
        params: {
          appointment_id: appointmentId,
          client_id: clientId,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setAppointmentDetails(data.data);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId, mounted, clientId]);

  useEffect(() => {
    getClientDetails();
    getCoachDetails();
    getAppointmentDetails();
  }, [getClientDetails, getCoachDetails, getAppointmentDetails]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const booking_info = appointmentDetails
    ? JSON.parse(appointmentDetails.booking_info)
    : null;

  return (
    <Page title={t("booking.thankyou.page.page")}>
      <div
        style={{
          height: "calc(100vh - 40px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px solid #F2F2F2",
            borderRadius: "10px",
            p: { xs: "20px", md: "100px 70px" },
            m: "10px",
          }}
        >
          <Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Mukta",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "40px",
                  lineHeight: "42px",
                  textAlign: "center",
                  letterSpacing: "-0.01em",
                  color: "#19181B",
                }}
              >
                {t("booking.thankyou.page.label")},{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {client ? client?.firstname : "Unname"}
                </span>
                !
              </Typography>
            </Box>

            <Box
              sx={{
                mt: "16px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "21px",
                  color: "#6E757C",
                }}
              >
                {t("booking.confirm.label.appointment_confirm.description")}{" "}
                <br />
                {t(
                  "booking.confirm.label.appointment_confirm.description_cont"
                )}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  mt: "40px",
                  mb: "16px",
                }}
              >
                <Avatar
                  alt={coachDetails?.name?.charAt()?.toUpperCase()}
                  sx={{
                    width: "80px",
                    height: "80px",
                    ...(!coachDetails?.avatar && {
                      fontSize: "40px",
                      fontWeight: 700,
                      letterSpacing: "-0.4px",
                      backgroundColor: "#7B1FA2",
                    }),
                  }}
                  // src={Constant.imageUrl("coach", coachDetails?.avatar)}
                  src={Constant.getFileInStorage("coach", coachDetails?.avatar)}
                >
                  {coachDetails?.name?.charAt()?.toUpperCase()}{" "}
                </Avatar>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    textAlign: "center",
                    color: "#6E757C",
                  }}
                >
                  {t("individual.booking.confirmed.label.you_are_meeting")}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "26px",
                    textAlign: "center",
                    color: "#19181B",
                    textTransform: "capitalize",
                  }}
                >
                  {coachDetails?.name}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "440px" },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "12px 24px",
                  gap: "20px",
                  border: "1px solid #DFDFE1",
                  borderRadius: "8px",
                  backgroundColor: "#F7F7F7",
                  mt: "32px",
                }}
              >
                <Box sx={{ width: "72px" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#6F6D77",
                      textAlign: "left",
                    }}
                  >
                    {t("individual.booking.confirmed.label.package")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#222128",
                      textTransform: "capitalize",
                    }}
                  >
                    {appointmentDetails?.offer?.name}
                  </Typography>

                  <DescTooltip
                    title={appointmentDetails?.offer?.name}
                    description={appointmentDetails?.offer?.description}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "16px",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "440px" },
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #DFDFE1",
                    borderRadius: "8px",
                    backgroundColor: "#F7F7F7",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "12px 24px",
                      gap: "16px",
                      borderBottom: 1,
                      borderColor: "#DFDFE1",
                    }}
                  >
                    <Box sx={{ width: "72px" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#6F6D77",
                          textAlign: "left",
                        }}
                      >
                        {t("individual.booking.confirmed.label.date")}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#222128",
                        }}
                      >
                        {moment(booking_info?.date).format("dddd, DD MMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "12px 24px",
                      gap: "16px",
                    }}
                  >
                    <Box sx={{ width: "72px" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#6F6D77",
                          textAlign: "left",
                        }}
                      >
                        {t("individual.booking.confirmed.label.time")}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#222128",
                        }}
                      >
                        {booking_info?.time} - {booking_info?.time_end}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                mt: "50px",
              }}
            >
              <Box>
                <PowerdBy />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </Page>
  );
};

export default ThankyouPage;
