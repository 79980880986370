import { Box } from "@mui/material";
import React from "react";
import TL from "./../assets/images/treffas_logo.png";

const Logo = () => {
  return (
    <>
      {/* medium  sc logo */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src={TL}
          alt={"treffas logo"}
          style={{
            // height: "35px",
            width: "142px",
            cursor: "pointer",
          }}
          onClick={() =>
            (window.location.href = process.env.REACT_APP_HOME_URL)
          }
        />
      </Box>

      {/* sm  sc logo */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <img
          src={TL}
          alt={"treffas logo"}
          style={{
            // height: "30px",
            width: "97px",
            cursor: "pointer",
            marginTop: "16px",
          }}
          onClick={() =>
            (window.location.href = process.env.REACT_APP_HOME_URL)
          }
        />
      </Box>
    </>
  );
};

export default Logo;
