import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import http from "src/utils/http";
import Logo from "src/components/Logo";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller as Fc } from "react-hook-form";
import TreffasStyle from "src/utils/TreffasStyle";
import GoogleLogin from "./google";
import LinkedLogin from "./linkedin";

import SideBg from "../SideBg";
import Pendo from "src/utils/Pendo";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Users } from "src/context";
import { useSnackbarContext } from "src/context/SnackbarContext";
import Page from "src/components/Page";

const Login = () => {
  const themes = useTheme();
  const navigate = useNavigate();
  const [isProcess, setIsProcess] = useState(false);
  const [isVerifyProcess, setIsVerifyProcess] = useState(false);

  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { mixpanel } = useContext(Users);
  const [showLink, setShowLink] = useState({
    enable: false,
    email: null,
  });
  const { showSnackbar } = useSnackbarContext();

  const baseURL = window.location.origin;

  const Schema = Yup.object({
    // organization: Yup.string().required("Organization is a required field"),
    email: Yup.string()
      .email(t("login.form.validation.email_invalid")) //"Enter valid email address.")
      .required(t("login.form.validation.email_required")), //"Email is a required field"),
    password: Yup.string().required(
      t("login.form.validation.pasword_required")
    ), //"Password is a required field"),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      // organization: "",
      email: "",
      password: "",
    },
  });

  const handleResentVerification = () => {
    let email = showLink.email;
    setIsVerifyProcess(true);
    http
      .post("/api/oauth/registration/sent-verification-email", {
        email: email,
        name: null,
        base_url: baseURL,
      })
      .then(({ data }) => {
        if (data.message === "success") {
          showSnackbar(
            t("registration.form.validation.verification-sent"),
            "success"
          );
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsVerifyProcess(false));
  };

  const onLogin = (values) => {
    setIsProcess(true);
    http
      .post("/api/oauth/login", {
        ...values,
        // organization: values.organization.trim().toLowerCase(),
      })
      .then(({ data }) => {
        if (data.message === "organization-not-found") {
          setError("organization", {
            type: "required",
            message: t("login.form.validation.organization_not_found"), // "Organization is not found.",
          });
        }

        if (data.message === "success") {
          // initialize pendo //
          Pendo.initialize(data.data);

          if (data.two_factor_enable) {
            navigate(
              `/two-factor/verification?token=${data.access_token}&id=${data?.data?.id}&organization=${data.data.organization.id}&mask=${data.mask}&type=${data.two_factor_type}`
            );
          } else {
            localStorage.setItem(`accessToken`, data.access_token);
            localStorage.setItem(`__loginType`, "coach");
            window.location.href = `/app/v2/calendar`;
          }
        }

        if (data.message === "account-invalid") {
          setError("submit", {
            type: "required",
            message: t("login.form.validation.credential_error"), //"Email or password is incorrect.",
          });
        }
        if (data.message === "account-unverified") {
          setShowLink({
            enable: true,
            email: values.email,
          });
          setError("submit", {
            type: "required",
            message: t("login.form.validation.account_unverified"), //"Account is not verified, follow the link sent in your email.",
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    mixpanel.track("Login Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Login">
      <Box
        sx={(themes) => ({
          height: "80px",
          alignItems: "center",
          [themes.breakpoints.up("md")]: {
            alignItems: "center",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
            pl: "99px",
            display: "none",
          },
          [themes.breakpoints.down("md")]: {
            justifyContent: "flex-start",
            pl: "24px !important",

            display: "flex",
          },
        })}
      >
        <Logo />
      </Box>

      <Box
        sx={{
          minHeight: { xs: "calc(100vh - 120px)", md: "calc(100vh - 0px)" },
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Box
          sx={{
            pt: {
              md: "120px",
            },
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "440px",
            }}
          >
            <Box>
              <Box mb={"4px"}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "32px",
                    lineHeight: "46px",
                    textAlign: "center",
                    letterSpacing: "-0.01em",
                    fontFamily: "var(--font-alike)",
                  }}
                >
                  {t("login.welcome")}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textAlign: "center",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("login.description.first")}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#838383",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("login.description.second")}
              </Typography>
            </Box>

            {/* button */}
            <Box
              sx={{
                mt: "33px",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
                gap: "20px",
              }}
            >
              <GoogleLogin />

              <LinkedLogin />
            </Box>

            {/* divider */}
            <Box
              sx={{
                width: "100%",
                mt: "34px",
                textAlign: "center",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  margin: "auto",
                }}
              >
                <Box>
                  <Divider>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#838383",
                        fontFamily: "var(--font-work-sans)",
                      }}
                    >
                      {t("login.description.third")}
                    </Typography>
                  </Divider>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                mt: "20px",
              }}
            >
              <Box
                sx={{
                  maxWidth: "460px",
                  m: "auto",
                }}
              >
                <form onSubmit={handleSubmit(onLogin)}>
                  <Box sx={{ mt: "24px" }}>
                    <FormControl fullWidth variant="standard">
                      <Typography
                        sx={{
                          ...TreffasStyle.formLabel,
                          fontFamily: "var(--font-work-sans)",
                        }}
                      >
                        {t("login.form.email")}
                      </Typography>

                      <Fc
                        name="email"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            placeholder={t("login.form.placeholder.email")}
                            type="text"
                            sx={TreffasStyle.formInput(themes)}
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ mt: "24px" }}>
                    <FormControl fullWidth variant="standard">
                      <Typography
                        sx={{
                          ...TreffasStyle.formLabel,
                          fontFamily: "var(--font-work-sans)",
                        }}
                      >
                        {t("login.form.password")}
                      </Typography>

                      <Fc
                        name="password"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            placeholder={t("login.form.placeholder.password")}
                            type={showPassword ? "text" : "password"}
                            sx={TreffasStyle.formInput(themes)}
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {!showPassword ? (
                                      <Visibility sx={{ fontSize: "18px" }} />
                                    ) : (
                                      <VisibilityOff
                                        sx={{ fontSize: "18px" }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "31px",
                            textAlign: "center",
                            color: "var(--color-orange)",
                            cursor: "pointer",
                            fontFamily: "var(--font-work-sans)",
                          }}
                          onClick={() => navigate("/reset/password")}
                        >
                          {t("login.form.fotgot_password")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {errors.submit && (
                    <Box>
                      <FormHelperText
                        error
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {errors.submit.message}
                      </FormHelperText>
                    </Box>
                  )}

                  {showLink.enable && (
                    <Box sx={{ textAlign: "center" }}>
                      <Button
                        onClick={handleResentVerification}
                        variant={"contained"}
                        size="small"
                        disabled={isVerifyProcess}
                        startIcon={
                          isVerifyProcess && (
                            <CircularProgress size={12} color="inherit" />
                          )
                        }
                        sx={{
                          color: "#FFF",
                        }}
                      >
                        Resent verification
                      </Button>
                    </Box>
                  )}

                  <Box sx={{ mt: "16px" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        textTransform: "inherit",
                        borderRadius: "8px",
                        fontSize: "14px",
                        padding: "10px",
                        lineHeight: "31px",
                        fontWeight: 400,
                        height: "48px",
                        backgroundColor: "var(--bg-black)",
                      }}
                      disabled={isProcess}
                      startIcon={
                        isProcess && (
                          <CircularProgress color="inherit" size={15} />
                        )
                      }
                    >
                      {t("login.form.login_btn")}
                    </Button>
                  </Box>

                  <Box sx={{ mt: "24px", pb: "24px" }}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#525F7F",
                      }}
                    >
                      {t("login.form.signup_text")}
                      <Typography
                        variant="subtitle"
                        color="primary"
                        sx={{
                          cursor: "pointer",
                          marginLeft: 0.5,
                          marginRight: 0.5,
                          fontWeight: 400,
                          color: "var(--color-orange)",
                        }}
                        onClick={() => navigate("/register")}
                        // onClick={() =>
                        //   window.open(
                        //     "https://folyeo.com/get-started-beta-testing",
                        //     "_blank"
                        //   )
                        // }
                      >
                        {t("login.form.signup_btn")}
                      </Typography>
                      {t("login.form.signup_text_cont")}
                    </Typography>
                  </Box>
                </form>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#000",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                Are you a client?
                <Typography
                  variant="subtitle"
                  sx={{
                    cursor: "pointer",
                    marginLeft: 0.5,
                    marginRight: 0.5,
                    fontWeight: 500,
                    color: "#D97855",
                    fontFamily: "var(--font-work-sans)",
                  }}
                  onClick={() => navigate("/login/client-portal")}
                >
                  Click here to sign in.
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            overflow: "hidden",
            width: "1100px",
            display: { xs: "none", lg: "block" },
          }}
        >
          <SideBg />
        </Box>
      </Box>
    </Page>
  );
};

export default Login;
