import { Avatar, Box, Menu, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import React, { useContext } from "react";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RelativeTime from "src/components/RelativeTime";
import { Notification, Users } from "src/context";
import Constant from "src/utils/Constant";
import Helper from "src/utils/Helper";
import Timezone from "src/utils/Timezone";
import http from "src/utils/http";

const DropdownNotification = ({
  notificationAnchor,
  setNotificationAnchor,
  notifications,
  getUnreadNotificationCount,
  unReadCount,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(Users);
  const navigate = useNavigate();
  const { setNotifications } = useContext(Notification);

  const handleNotificationFrom = (data) => {
    let meta = JSON.parse(data.notification_meta);

    setNotifications(notifications.filter((x) => x.id !== data.id));
    setNotificationAnchor(null);

    Helper.setNotificationAsRead(data.id);

    switch (data.notification_type) {
      case "appointment":
        navigate(`/app/appointments/details/${meta.appointment_id}`);
        return;
      case "survey":
        navigate(`/app/survies/details/${meta.survey_id}`);
        return;
      case "email-messaging":
        navigate(
          `/app/clients-depreciated/details/${meta.client_id}?tab=messages`
        );
        return;
      default:
        return;
    }
  };

  const boldText = (content) => {
    const text = content.replace(
      /<variable>(.*?)<\/variable>/g,
      (match, capturedText) => {
        return (
          '<b style="text-transform: capitalize;">' + capturedText + "</b>"
        );
      }
    );

    return text;
  };

  const setUnreadNotificationToRead = async () => {
    await http.post("/api/notifications/unread-setto-read", {
      user_id: user?.id,
      organization_id: user?.organization?.id,
    });

    setNotifications([]);
    getUnreadNotificationCount();
  };

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={notificationAnchor}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(notificationAnchor)}
      onClose={() => setNotificationAnchor(null)}
      elevation={0}
      PaperProps={{
        sx: {
          width: "430px",
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.05)",
          padding: "8px 0px",
          borderRadius: "12px",
          border: "1px solid #DFE2E6",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
          p: "12px 20px 16px 20px",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Mukta",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "22px",
            }}
          >
            {t("notification.page-title")}{" "}
            {notifications.length > 0 && unReadCount > 0 && `(${unReadCount})`}
          </Typography>
        </Box>

        {notifications.length > 0 && unReadCount > 0 && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Check size={16} color={"var(--color-orange)"} />
            <Typography
              sx={{
                color: "var(--color-orange)",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                cursor: "pointer",
              }}
              onClick={() => {
                setUnreadNotificationToRead();
                setNotificationAnchor(null);
              }}
            >
              {t("notifications.label.mark_all_as_read")}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        className="notifications-scrollbar"
        sx={{
          maxHeight: "492px",
          overflowY: "auto",
        }}
      >
        {notifications.length > 0 ? (
          notifications.map((data, key) => {
            let coachTimezone =
              user?.timezone_utc ?? Timezone.getGuessTimezone();
            let isRead = Boolean(data.is_read);

            const message = boldText(data.notification);
            return (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "22px 16px 20px 16px",
                  borderTop: "1px solid #DFE2E6",
                  ...(notifications.length === key + 1 && {
                    borderBottom: "1px solid #DFE2E6",
                  }),

                  ...(key === 0 && {
                    borderTop: "0px solid #DFE2E6",
                  }),

                  ...(!isRead && {
                    backgroundColor: "#F4F5F6",
                  }),

                  "&:hover": {
                    backgroundColor: "#F0F7FF",
                    cursor: "pointer",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleNotificationFrom(data);
                }}
                key={key}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box>
                    <Avatar
                      sx={{
                        width: "40px",
                        height: "40px",
                        fontSize: "18px",
                        color: "#4A5056",
                        ...(!data?.client?.image && {
                          backgroundColor: "#F8F9FA",
                          border: "1px solid #F4F5F6",
                        }),
                        fontWeight: 500,
                        ...(!isRead && {
                          color: "#FFF",
                          ...(!data?.client?.image && {
                            backgroundColor: "var(--bg-black)",
                          }),
                        }),
                      }}
                      alt={
                        data?.client?.firstname?.toUpperCase() ??
                        data.notification
                      }
                      // src={Constant.imageUrl("clients", data?.client?.image)}
                      src={Constant.getFileInStorage(
                        "clients",
                        data?.client?.image
                      )}
                    />
                  </Box>

                  <Box
                    sx={
                      {
                        // width: "294px",
                      }
                    }
                  >
                    <Typography
                      sx={{
                        color: "#4A5056",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "22px",
                        ...(!isRead && {
                          color: "#212529",
                        }),
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message),
                      }}
                    ></Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "80px",
                    textAlign: "right",
                  }}
                >
                  {isRead ? (
                    <Typography
                      sx={{
                        color: "#6E757C",
                        textAlign: "right",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      <RelativeTime
                        date={data.created_at}
                        timezone={coachTimezone}
                        type="shorten"
                      />
                    </Typography>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle cx="4" cy="4" r="4" fill="#0165DF" />
                    </svg>
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              p: "22px 16px 20px 16px",
              border: "1px solid #DFE2E6",
            }}
          >
            <Typography
              align="center"
              sx={{
                color: "#6E757C",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              {t("notifications.label.no_notifications")}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "stretch",
          pt: "10px",
        }}
      >
        <Typography
          sx={{
            color: "var(--color-orange)",
            textAlign: "right",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            cursor: "pointer",
          }}
          onClick={() => {
            setNotificationAnchor(null);
            navigate(`/app/notifications`);
          }}
        >
          {t("notifications.label.see_all")}
        </Typography>
      </Box>
    </Menu>
  );
};

export default DropdownNotification;
