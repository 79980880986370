import { Avatar, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Constant from "src/utils/Constant";
import { useLocation } from "react-router-dom";
import FooterImage from "../../individual-booking/footer-image";
import http from "src/utils/http";
import { useTranslation } from "react-i18next";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Success = () => {
  const searchParams = useQuery();
  const userId = searchParams.get("user_id");
  const organizationId = searchParams.get("organization_id");
  const { t } = useTranslation();
  const [coachDetails, setCoachDetails] = useState(null);

  const getCoachDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/coach-details", {
        params: {
          user_id: userId,
          organization_id: organizationId,
        },
      })
      .then(({ data }) => {
        setCoachDetails(data);
      })
      .catch((err) => console.log(err.message));
  }, [userId, organizationId]);

  React.useEffect(() => {
    getCoachDetails();
  }, [getCoachDetails]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "calc(100vh - 75px)",
      }}
    >
      <Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Mukta",
                fontWeight: 700,
                fontSize: "40px",
                lineHeight: "42px",
                color: "#19181B",
              }}
            >
              {t("booking.confirm.label.appointment_sent")}
            </Typography>
          </Box>

          <Box
            sx={{
              maxWidth: "398px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "21px",
                color: "#6E757C",
                mt: "16px",
              }}
            >
              {t("booking.confirm.label.appointment_sent.description")}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: "40px",
              mb: "16px",
            }}
          >
            <Avatar
              alt={coachDetails?.name?.charAt()?.toUpperCase()}
              sx={{
                width: "80px",
                height: "80px",
                ...(!coachDetails?.avatar && {
                  fontSize: "40px",
                  fontWeight: 700,
                  letterSpacing: "-0.4px",
                  backgroundColor: "#7B1FA2",
                }),
              }}
              // src={Constant.imageUrl("coach", coachDetails?.avatar)}
              src={Constant.getFileInStorage("coach", coachDetails?.avatar)}
            >
              {coachDetails?.name?.charAt()?.toUpperCase()}
            </Avatar>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "16px",
                textAlign: "center",
                color: "#6E757C",
              }}
            >
              {t(
                "individual.booking.schedule.choose_schedule.you_are_meeting_with"
              )}
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "26px",
                textAlign: "center",
                color: "#19181B",
                textTransform: "capitalize",
              }}
            >
              {coachDetails?.name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "72px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FooterImage />
      </Box>
    </Box>
  );
};

export default Success;
