import React, {
  useEffect,
  useCallback,
  useState,
  Fragment,
  useContext,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import http from "src/utils/http";
import IsMounted from "src/components/IsMounted";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Radio,
  FormHelperText,
  Avatar,
} from "@mui/material";
import TreffasStyle from "src/utils/TreffasStyle";
import PageNotFound from "src/components/PageNotFound";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import Page from "src/components/Page";
import DescTooltip from "../schedule/booking-header/DescTooltip";
import Constant from "src/utils/Constant";
import { Users } from "src/context";
import TreffasCopyToClipboard from "src/components/TreffasCopyToClipboard";
import Booking from "src/utils/Booking";
import { useSnackbarContext } from "src/context/SnackbarContext";
import AddCouponDialog from "../../coupons/AddCouponDialog";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BookingConfirmationWithPayment = () => {
  const searchParams = useQuery();
  const organization_id = searchParams.get("organization_id");
  const offer_id = searchParams.get("offer_id");
  const user_id = searchParams.get("user_id");
  const appointment_id = searchParams.get("appointment_id");
  const client_id = searchParams.get("client_id");
  const session_slug = searchParams.get("session_slug");
  const { mixpanel } = useContext(Users);
  const { t } = useTranslation();

  // const { org, user_id, offer_id, appointment_id, client_id } =
  //   useParams();
  const [offerDetails, setOfferDetails] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [formError, setFormError] = useState([]);

  const mounted = IsMounted();

  const [appDetails, setAppDetails] = useState({});
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbarContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isProcess, setIsProcess] = useState(false);
  const [coachDetails, setCoachDetails] = useState(null);

  const [dialog, setDialog] = useState({
    open: false,
    data: null,
  });

  const price = offerDetails?.price;
  const discountRate = dialog?.data?.coupon_code
    ? dialog?.data?.coupon_type === "fixed"
      ? dialog?.data?.coupon_rate
      : dialog?.data?.coupon_rate / 100
    : 0; // Convert percentage to decimal

  const discountedPrice = (
    dialog?.data?.coupon_type === "percentage"
      ? price - price * discountRate
      : price - discountRate
  ).toFixed(2);

  /*** get appointment details by client id and appointment id **/
  const getAppointmentDetails = useCallback(async () => {
    http
      .get(`/api/general/individual/appointment/details`, {
        params: {
          appointment_id,
          client_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          if (Boolean(parseInt(data.data?.client_confirmation))) {
            showSnackbar(
              t("booking.confirm.alert.success_already_confirm"),
              "success"
            );
          }
          setAppDetails(data.data);
          setOfferDetails(data.data?.offer ?? null);

          const _payments =
            data.data?.offer?.custom_options?.enable_payments ?? [];
          const enabledPayments = _payments?.filter((payment) =>
            Booking.isIntegrationActive(data.data?.offer, payment)
          );

          if (enabledPayments.length > 0 && enabledPayments.length === 1) {
            if (enabledPayments[0] === "stripe") {
              setSelectedPaymentMethod({
                type: "stripe",
                mode: data.data?.offer?.custom_options?.stripe_config.mode,
                stripe_product_price_id:
                  data.data?.offer?.custom_options?.stripe_config
                    .stripe_product_price_id,
                phone_number_collection:
                  data.data?.offer?.custom_options?.stripe_config.phone_number,
              });
            }

            // check if payment method is paypal
            if (enabledPayments[0] === "paypal") {
              setSelectedPaymentMethod({
                type: "paypal",
                mode: data.data?.offer?.custom_options?.paypal_config.mode,
                paypal_plan_id:
                  data.data?.offer?.custom_options?.paypal_config
                    .paypal_plan_id,
                paypal_product_id:
                  data.data?.offer?.custom_options?.paypal_config
                    .paypal_product_id,
                payment_method:
                  data.data?.offer?.custom_options?.paypal_config
                    .payment_method,
              });
            }
          }
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_id, mounted, client_id]);

  const handleBookingConfirmation = () => {
    const isBankTransfer =
      offerDetails?.custom_options?.bank_transfer?.enable ?? false;
    let params = new URLSearchParams();
    params.append("appointment_id", appointment_id);
    params.append("client_id", client_id);
    params.append("organization_id", organization_id);
    params.append("user_id", offerDetails?.user_id);

    let error = [];

    if (parseInt(offerDetails?.price) > 0 && !isBankTransfer) {
      params.append("paymentMethod", JSON.stringify(selectedPaymentMethod));
      params.append("session_slug", session_slug);
      params.append("base_url", window.location.origin);
      params.append("offerDetails", JSON.stringify(offerDetails));
      params.append(
        "currency",
        offerDetails?.custom_options?.currency ?? "USD"
      );
      params.append("price", discountedPrice);

      params.append(
        "success_url",
        `${window.location.origin}/appointment-individual/booking/payment-successful`
      );
      params.append(
        "cancel_url",
        `${window.location.origin}/appointment-individual/booking/payment-cancelled`
      );

      if (dialog?.data?.coupon_code) {
        params.append("coupon_used", JSON.stringify(dialog.data));
      }

      if (selectedPaymentMethod === null) {
        error = "payment-method-required";
        setFormError((formError) =>
          formError.concat("payment-method-required")
        );
      }
    }

    if (error.length > 0) {
      console.log(`form has an error:`, error);
      return;
    }

    setIsProcess(true);

    http
      .post(`/api/general/individual/confirm/booking/with-payment`, params)
      .then((response) => {
        if (response.data.message === "success") {
          if (response.data.payment_url?.trim() === "") {
            showSnackbar(t("booking.confirm.alert.success_confirm"), "success");
            navigate(
              `/appointment-individual/booking/thank-you?client_id=${client_id}&appointment_id=${appointment_id}&user_id=${user_id}&organization_id=${organization_id}`
            );
          } else {
            setIsProcess(true);
            window.location.href = response.data.payment_url;
          }
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  const handleSelectedPaymentMethod = (payment) => {
    // check if payment method is stripe //

    if (payment === "stripe") {
      setSelectedPaymentMethod({
        type: "stripe",
        mode: offerDetails?.custom_options?.stripe_config.mode,
        stripe_product_price_id:
          offerDetails?.custom_options?.stripe_config.stripe_product_price_id,
        phone_number_collection:
          offerDetails?.custom_options?.stripe_config.phone_number,
      });
    }

    // check if payment method is paypal
    if (payment === "paypal") {
      setSelectedPaymentMethod({
        type: "paypal",
        mode: offerDetails?.custom_options?.paypal_config.mode,
        paypal_plan_id:
          offerDetails?.custom_options?.paypal_config.paypal_plan_id,
        paypal_product_id:
          offerDetails?.custom_options?.paypal_config.paypal_product_id,
        payment_method:
          offerDetails?.custom_options?.paypal_config.payment_method,
      });
    }
  };

  const getCoachDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/coach-details", {
        params: {
          user_id,
          organization_id: organization_id,
        },
      })
      .then(({ data }) => {
        setCoachDetails(data);
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));
  }, [user_id, organization_id]);

  useEffect(() => {
    mixpanel.track("Booking Confirmation With Payment", {
      location: window.location.href,
    });

    getAppointmentDetails();
    getCoachDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAppointmentDetails, getCoachDetails]);

  let bookingDetails = appDetails?.booking_info
    ? JSON.parse(appDetails?.booking_info)
    : null;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (Object.keys(appDetails).length === 0) {
    return <PageNotFound />;
  }

  const banks = bookingDetails?.selectedOffer
    ? JSON.parse(bookingDetails?.selectedOffer)?.bank_transfer
    : null;

  const _payments = offerDetails?.custom_options?.enable_payments ?? [];
  const enabledPayments = _payments?.filter((payment) =>
    Booking.isIntegrationActive(offerDetails, payment)
  );

  const bankTransferActive = Booking.isIntegrationActive(
    offerDetails,
    "bank transfer"
  );

  let hasPaymentError = Booking.nonFreePackageHasAvailablePayment(
    offerDetails,
    offerDetails.enabled_integrations,
    "booking"
  );

  return (
    <Fragment>
      <Page title={t("booking.confirm.page")}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 30px)",
            flexDirection: "column",
            px: "20px",
            mt: "20px",
            pb: "24px",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "508px" },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Mukta",
                  fontWeight: 700,
                  fontSize: "40px",
                  lineHeight: "42px",
                  textAlign: "center",
                  letterSpacing: "-0.01em",
                  color: "#19181B",
                }}
              >
                {t(
                  "individual.booking.confirmed.label.confirm_your_appointment"
                )}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "21px",
                  textAlign: "center",
                  color: "#6E757C",
                }}
              >
                {t(
                  "individual.booking.confirmed.label.confirm_your_appointment.description"
                )}{" "}
                <br />
                {t(
                  "individual.booking.confirmed.label.confirm_your_appointment.description.continue"
                )}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: "40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  sx={{
                    width: "80px",
                    height: "80px",

                    ...(!coachDetails?.avatar && {
                      fontSize: "40px",
                      fontWeight: 700,
                      letterSpacing: "-0.4px",
                      backgroundColor: "#7B1FA2",
                    }),
                  }}
                  alt={coachDetails?.name?.charAt()?.toUpperCase()}
                  // src={Constant.imageUrl("coach", coachDetails?.avatar)}
                  src={Constant.getFileInStorage("coach", coachDetails?.avatar)}
                >
                  {coachDetails?.name?.charAt()?.toUpperCase()}{" "}
                </Avatar>
              </Box>

              <Box
                sx={{
                  width: { xs: "100%" },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: "640px" },
                  }}
                >
                  <Box sx={{ mt: "16px" }}>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "12px",
                          lineHeight: "16px",
                          textAlign: "center",
                          color: "#6E757C",
                        }}
                      >
                        {t(
                          "individual.booking.confirmed.label.you_are_meeting"
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "20px",
                          lineHeight: "26px",
                          textAlign: "center",
                          color: "#19181B",
                          textTransform: "capitalize",
                        }}
                      >
                        {coachDetails?.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: { xs: "100%" },
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "12px 24px",
                      gap: "20px",
                      border: "1px solid #DFDFE1",
                      borderRadius: "8px",
                      backgroundColor: "#F7F7F7",
                      mt: "32px",
                    }}
                  >
                    <Box sx={{ width: "72px" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#6F6D77",
                        }}
                      >
                        {t("individual.booking.confirmed.label.package")}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", gap: "7px", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#222128",
                          textTransform: "capitalize",
                        }}
                      >
                        {offerDetails?.name}
                      </Typography>

                      <DescTooltip
                        title={offerDetails?.name}
                        description={offerDetails?.description}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      border: "1px solid #DFDFE1",
                      borderRadius: "8px",
                      backgroundColor: "#F7F7F7",
                      mt: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "12px 24px",
                        gap: "16px",
                        borderBottom: 1,
                        borderColor: "#DFDFE1",
                      }}
                    >
                      <Box sx={{ width: "72px" }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "15px",
                            lineHeight: "20px",
                            color: "#6F6D77",
                          }}
                        >
                          {t("individual.booking.confirmed.label.date")}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "15px",
                            lineHeight: "20px",
                            color: "#222128",
                          }}
                        >
                          {moment(bookingDetails?.date).format(
                            "dddd, DD MMM YYYY"
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "12px 24px",
                        gap: "16px",
                      }}
                    >
                      <Box sx={{ width: "72px" }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "15px",
                            lineHeight: "20px",
                            color: "#6F6D77",
                          }}
                        >
                          {t("individual.booking.confirmed.label.time")}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "15px",
                            lineHeight: "20px",
                            color: "#222128",
                          }}
                        >
                          {moment(
                            `${bookingDetails?.date} ${bookingDetails?.time}`
                          ).format("hh:mm A")}{" "}
                          -{" "}
                          {moment(
                            `${bookingDetails?.date} ${bookingDetails?.time}`
                          )
                            .add(offerDetails?.duration, "minutes")
                            .format("hh:mm A")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ my: "20px" }}>
                    {parseInt(offerDetails?.price) > 0 &&
                      enabledPayments?.length > 0 && (
                        <Box>
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Typography sx={TreffasStyle.formLabel}>
                                  {t("booking.confirm.label.payment_methods")}
                                </Typography>
                              </Box>

                              {selectedPaymentMethod?.mode === "payment" && (
                                <Box>
                                  <Button
                                    onClick={() =>
                                      setDialog((prev) => {
                                        return {
                                          ...prev,
                                          open: true,
                                        };
                                      })
                                    }
                                    disabled={
                                      dialog?.data?.coupon_code ? true : false
                                    }
                                  >
                                    Add Coupon
                                  </Button>

                                  <AddCouponDialog
                                    dialog={dialog}
                                    setDialog={setDialog}
                                    packageId={offer_id}
                                  />
                                </Box>
                              )}
                            </Box>

                            {formError.includes("payment-method-required") && (
                              <Box ml={1} mb={1}>
                                <FormHelperText error>
                                  {t(
                                    "booking.confirm.error.payment_option_for_nonfree"
                                  )}
                                </FormHelperText>
                              </Box>
                            )}
                          </Box>

                          <Grid container spacing={2}>
                            {enabledPayments.map((payment, paymentKey) => (
                              <Grid
                                item
                                xs={12}
                                md={
                                  parseInt(
                                    offerDetails?.custom_options
                                      ?.enable_payments?.length
                                  ) === 1
                                    ? 12
                                    : 6
                                }
                                key={paymentKey}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #EFF0F7",
                                    p: 2,
                                    borderRadius: "16px",
                                  }}
                                  width={"100%"}
                                >
                                  <Grid container spacing={1}>
                                    <Grid item xs={2} md={3}>
                                      <Radio
                                        checked={
                                          selectedPaymentMethod?.type ===
                                          payment
                                        }
                                        onClick={(e) =>
                                          handleSelectedPaymentMethod(payment)
                                        }
                                        value={payment.id}
                                        name={"size-radio-button-demo"}
                                        inputProps={{
                                          "aria-label": payment.id,
                                        }}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 25,
                                          },
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={8} md={9}>
                                      <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                      >
                                        <Typography
                                          sx={{
                                            textTransform: "capitalize",
                                            fontWeight: 600,
                                            flexGrow: 1,
                                          }}
                                          noWrap
                                        >
                                          {payment}
                                        </Typography>

                                        <Typography
                                          sx={{
                                            fontSize: "10px",
                                            lineHeight: "14px",
                                          }}
                                        >
                                          {payment === "stripe" && (
                                            <>
                                              {/* show if stripe is direct payment */}
                                              {offerDetails?.custom_options
                                                ?.stripe_config.mode ===
                                                "payment" && (
                                                <span> One Time Payment </span>
                                              )}

                                              {/* show if stripe if for subscription */}
                                              {offerDetails?.custom_options
                                                ?.stripe_config.mode ===
                                                "subscription" && (
                                                <>
                                                  <span>
                                                    {` ${offerDetails?.custom_options?.stripe_config.billing_period} Subscription`}
                                                  </span>

                                                  <span>
                                                    {` ${offerDetails?.custom_options?.stripe_config.usage_type}`}
                                                  </span>
                                                </>
                                              )}
                                            </>
                                          )}

                                          {payment === "paypal" && (
                                            <>
                                              {/* show if paypal is direct payment */}
                                              {offerDetails?.custom_options
                                                ?.paypal_config.mode ===
                                                "payment" && (
                                                <span>One Time Payment</span>
                                              )}

                                              {/* show if paypal if for subscription */}
                                              {offerDetails?.custom_options
                                                ?.paypal_config.mode ===
                                                "subscription" && (
                                                <span>
                                                  {` ${offerDetails?.custom_options?.paypal_config.interval_unit} Subscription`}
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      )}

                    {bankTransferActive && banks?.enable && (
                      <Box
                        sx={{
                          p: "20px",
                          border: "1px solid #EAECEF",
                          borderRadius: "12px",
                          minHeight: "342px",
                          width: "100%",
                          backgroundColor: "#FFF",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "18px",
                              lineHeight: "23px",
                            }}
                          >
                            Bank Transfer Instructions
                          </Typography>

                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "18px",
                              color: "#6E757C",
                            }}
                          >
                            Make transfer to the account details provided
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            p: "20px",
                            background: "#F5F9FF",
                            border: "1px solid #99C7FF",
                            borderRadius: "12px",
                            mt: "16px",
                          }}
                        >
                          <Grid container spacing={"16px"}>
                            <Grid item xs={12}>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#6E757C",
                                  }}
                                >
                                  Bank Name
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    lineHeight: "20px",
                                    color: "#222128",
                                  }}
                                >
                                  {banks?.bank_accounts?.bank_info?.bank_name}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#6E757C",
                                  }}
                                >
                                  Account name
                                </Typography>

                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    lineHeight: "20px",
                                    color: "#222128",
                                  }}
                                >
                                  {
                                    banks?.bank_accounts?.bank_info
                                      ?.account_name
                                  }
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#6E757C",
                                  }}
                                >
                                  Account number
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      lineHeight: "20px",
                                      color: "#222128",
                                    }}
                                  >
                                    {
                                      banks?.bank_accounts?.bank_info
                                        ?.account_number
                                    }
                                  </Typography>

                                  <TreffasCopyToClipboard
                                    text={
                                      banks?.bank_accounts?.bank_info
                                        ?.account_number
                                    }
                                  />
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#6E757C",
                                  }}
                                >
                                  IBAN
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      lineHeight: "20px",
                                      color: "#222128",
                                    }}
                                  >
                                    {banks?.bank_accounts?.bank_info?.iban}
                                  </Typography>

                                  <TreffasCopyToClipboard
                                    text={banks?.bank_accounts?.bank_info?.iban}
                                  />
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#6E757C",
                                  }}
                                >
                                  Swiftcode (BIC)
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      lineHeight: "20px",
                                      color: "#222128",
                                    }}
                                  >
                                    {banks?.bank_accounts?.bank_info?.swiftcode}
                                  </Typography>

                                  <TreffasCopyToClipboard
                                    text={
                                      banks?.bank_accounts?.bank_info?.swiftcode
                                    }
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {hasPaymentError && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          border: "1px solid #F7F7F7",
                          backgroundColor: "#ee6d6d",
                          borderRadius: "12px",
                          minHeight: "80px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "21px",
                            textAlign: "center",
                            color: "#fff",
                          }}
                        >
                          Unable to generate a booking without charges; the
                          coach is not equipped to process payments.
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "12px",
                      mt: "40px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/appointments/individual/booking/cancel/${organization_id}/${user_id}/${offer_id}/${appointment_id}/${client_id}`
                          )
                        }
                        variant="outlined"
                        color={"primary"}
                        fullWidth
                        sx={{
                          minWidth: "120px",
                          height: "48px",
                          fontSize: "14px",
                          borderRadius: "12px",
                          ...TreffasStyle.buttonHover,
                        }}
                      >
                        {t("booking.button.cancel")}
                      </Button>
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      <Button
                        variant="contained"
                        color={"primary"}
                        fullWidth
                        sx={{
                          minWidth: "120px",
                          height: "48px",
                          fontSize: "14px",
                          borderRadius: "12px",
                          ...TreffasStyle.buttonHover,
                        }}
                        disabled={
                          isProcess ||
                          Boolean(parseInt(appDetails?.client_confirmation)) ||
                          hasPaymentError
                        }
                        startIcon={
                          isProcess && (
                            <CircularProgress color="inherit" size={15} />
                          )
                        }
                        onClick={() => handleBookingConfirmation()}
                      >
                        {t("booking.button.confirm")} -{" "}
                        {
                          Constant.getCurrency(offerDetails?.currency ?? "USD")
                            .symbols
                        }
                        {discountedPrice}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Page>
    </Fragment>
  );
};

export default BookingConfirmationWithPayment;
