import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import FooterImage from "../../individual-booking/footer-image";
import moment from "moment";
import Constant from "src/utils/Constant";
import DescTooltip from "../../individual-booking/schedule/booking-header/DescTooltip";
import { useTranslation } from "react-i18next";

const Success = ({
  selectedSessionTime,
  selectedOffer,
  coachDetails,
  getValues,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 120px)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%", mt: "50px" }}>
        <Box>
          <Typography
            sx={{
              fontFamily: "Mukta",
              fontWeight: 700,
              fontSize: "30px",
              lineHeight: "32px",
              color: "#19181B",
              textAlign: "center",
            }}
          >
            {t("individual.booking.schedule.alert.booking_rescheduled")}
          </Typography>

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "21px",
              color: "#6E757C",
              mt: "16px",
              textAlign: "center",
            }}
          >
            {t(
              "individual.booking.schedule.alert.booking_scheduled.email_sent"
            )}
          </Typography>
        </Box>

        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "40px" }}>
            <Avatar
              alt={coachDetails?.name?.charAt()?.toUpperCase()}
              sx={{
                width: "80px",
                height: "80px",
                ...(!coachDetails?.avatar && {
                  fontSize: "40px",
                  fontWeight: 700,
                  letterSpacing: "-0.4px",
                  backgroundColor: "#7B1FA2",
                }),
              }}
              src={Constant.getFileInStorage("coach", coachDetails?.avatar)}
              // src={Constant.imageUrl("coach", coachDetails?.avatar)}
            >
              {coachDetails?.name?.charAt()?.toUpperCase()}
            </Avatar>
          </Box>

          <Box sx={{ mt: "16px" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#6E757C",
                textAlign: "center",
              }}
            >
              {t(
                "individual.booking.schedule.choose_schedule.you_are_meeting_with"
              )}
            </Typography>

            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "26px",
                color: "#19181B",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {coachDetails?.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "440px" },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 24px",
                gap: "20px",
                border: "1px solid #DFDFE1",
                borderRadius: "8px",
                backgroundColor: "#F7F7F7",
                mt: "32px",
              }}
            >
              <Box sx={{ width: "72px" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "20px",
                    color: "#6F6D77",
                  }}
                >
                  {t("individual.booking.schedule.choose_schedule.package")}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "20px",
                    color: "#222128",
                    textTransform: "capitalize",
                  }}
                >
                  {selectedOffer?.name}
                </Typography>

                <DescTooltip
                  title={selectedOffer?.name}
                  description={selectedOffer?.description}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "440px" },
            }}
          >
            <Box
              sx={{
                mt: "16px",
                mb: "8px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "10px",
                  lineHeight: "11px",
                  letterSpacing: "0.07em",
                  color: "#A1A1A1",
                }}
              >
                {t("individual.booking.schedule.new_schedule")}
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid #DFDFE1",
                borderRadius: "8px",
                backgroundColor: "#F7F7F7",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "12px 24px",
                  gap: "16px",
                  borderBottom: 1,
                  borderColor: "#DFDFE1",
                }}
              >
                <Box sx={{ width: "72px" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#6F6D77",
                    }}
                  >
                    {t("individual.booking.schedule.choose_schedule.guest")}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#222128",
                      textTransform: "capitalize",
                    }}
                  >
                    {getValues("firstname")} {getValues("lastname")}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "12px 24px",
                  gap: "16px",
                  borderBottom: 1,
                  borderColor: "#DFDFE1",
                }}
              >
                <Box sx={{ width: "72px" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#6F6D77",
                    }}
                  >
                    {t("individual.booking.schedule.choose_schedule.date")}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#222128",
                    }}
                  >
                    {moment(selectedSessionTime?.start).format(
                      "dddd, DD MMM YYYY"
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "12px 24px",
                  gap: "16px",
                }}
              >
                <Box sx={{ width: "72px" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#6F6D77",
                    }}
                  >
                    {t("individual.booking.schedule.choose_schedule.time")}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#222128",
                    }}
                  >
                    {moment(selectedSessionTime?.start).format("hh:mm A")} -{" "}
                    {moment(selectedSessionTime?.start)
                      .add(selectedOffer?.duration, "minutes")
                      .format("hh:mm A")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: "61px",
            width: "100%",
          }}
        >
          <FooterImage />
        </Box>
      </Box>
    </Box>
  );
};

export default Success;
