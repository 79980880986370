import { Box, Container, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SurveyForm from "./SurveyForm";
import { Users } from "src/context";
import http from "src/utils/http";
import LoadingScreen from "src/components/LoadingScreen";
import PageNotFound from "src/components/PageNotFound";
import IsMounted from "src/components/IsMounted";
import { useParams } from "react-router-dom";
import Survies from "src/utils/Survies";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TL from "src/assets/images/folyeo.png";
import Constant from "src/utils/Constant";
import Helper from "src/utils/Helper";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Take = () => {
  const { mixpanel } = useContext(Users);
  const { surveyId, surveySlug } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [details, setDetails] = useState(null);
  const mounted = IsMounted();

  const getSurveyDetails = React.useCallback(() => {
    http
      .get("/api/general/survey/details", {
        params: {
          survey_id: surveyId,
          slug: surveySlug,
        },
      })
      .then((response) => {
        if (mounted.current) {
          setDetails(response.data.data);
        }
      })
      .catch((err) => console.log("error:", err.message))
      .finally(() => setIsLoading(false));
  }, [mounted, surveyId, surveySlug]);

  useEffect(() => {
    getSurveyDetails();
  }, [getSurveyDetails]);

  React.useEffect(() => {
    mixpanel.track("Take Survey", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status =
    details?.options?.status !== "inactive" ? "active" : "inactive";

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (details === null || status === "inactive") {
    return <PageNotFound orientation={"column"} />;
  }

  const surveyThemes = Survies.getTheme(
    details?.options?.themes?.value ?? "default"
  );

  const isMoreColor = details?.options?.themes?.isMoreColor?.enable ?? false;
  const moreColor = details?.options?.themes?.isMoreColor?.color ?? "";

  const isIframe = window.self !== window.top;
  const isColorDark = Helper.isColorDark(
    isMoreColor ? moreColor : surveyThemes.configuration.background
  );

  return (
    <Box
      sx={{
        backgroundColor: isMoreColor
          ? moreColor
          : surveyThemes.configuration.background,
      }}
    >
      <Box
        sx={{
          minHeight: isIframe ? "inherit" : "calc(100vh - 70px)", // 70px is the height og poweredby component
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* header */}
        <Box>
          <Box
            sx={{
              py: "32px",
              borderBottom: `1px solid ${surveyThemes.configuration.border}`,
              mb: "24px",
            }}
          >
            <Container
              maxWidth={"sm"}
              sx={{
                padding: "0px !important",
              }}
            >
              <Box>
                {details?.logo && (
                  <Box
                    sx={{
                      mb: "40px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      alt="Treffas logo"
                      // src={Constant.imageUrl("coach/survey", details?.logo)}
                      src={Constant.getFileInStorage(
                        "coach/survey",
                        details?.logo
                      )}
                      style={{
                        height: "100px",
                      }}
                    />
                  </Box>
                )}

                <Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "28px",
                      lineHeight: "46px",
                      color: isColorDark ? "#FFF" : "#343544",
                      textTransform: "capitalize",
                    }}
                  >
                    {details?.name}
                  </Typography>

                  {/* <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "normal",
                      color: "#343544",
                    }}
                  >
                    {details?.description}
                  </Typography> */}
                </Box>
              </Box>
            </Container>
          </Box>

          <Container
            maxWidth={"sm"}
            sx={{
              padding: "0px !important",
              marginBottom: "80px",
            }}
          >
            <Box>
              <SurveyForm
                details={details}
                setDetails={setDetails}
                surveyThemes={surveyThemes}
                isMoreColor={isMoreColor}
                moreColor={moreColor}
                isColorDark={isColorDark}
              />
            </Box>
          </Container>
        </Box>

        {/* form */}
      </Box>

      {/* powered by */}
      <Box>
        <PowerdBy surveyThemes={surveyThemes} isMoreColor={isMoreColor} />
      </Box>
    </Box>
  );
};

export default Take;

const PowerdBy = ({ surveyThemes, isMoreColor }) => {
  const searchParams = useQuery();
  const display = searchParams.get("display"); // id embeded disable on click on treffas logo
  const { t } = useTranslation();
  const isIframe = window.self !== window.top;

  return (
    <Box
      sx={{
        borderTop: `1px solid ${surveyThemes.configuration.border}`,
        width: "100%",
        height: 70,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          variant={"caption"}
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "31px",
            color: isMoreColor ? surveyThemes.configuration.border : "#838383",
          }}
        >
          {t("powered_by")}
        </Typography>

        <img
          src={TL}
          alt={"treffas logo"}
          style={{
            // width: "71.2px",
            height: "20px",
            marginLeft: "6px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (display === "embeded" || isIframe) {
              window.open(window.location.origin, "_blank");
              return;
            }

            window.location.href = "https://folyeo.com";
            // navigate("/");
          }}
        />
      </Box>
    </Box>
  );
};
