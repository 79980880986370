import React, { useEffect, useCallback, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import http from "src/utils/http";
import IsMounted from "src/components/IsMounted";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Avatar,
} from "@mui/material";
import TreffasStyle from "src/utils/TreffasStyle";
import PageNotFound from "src/components/PageNotFound";
import { useTranslation } from "react-i18next";
import Page from "src/components/Page";
import LoadingScreen from "src/components/LoadingScreen";
import DescTooltip from "../schedule/booking-header/DescTooltip";
import Constant from "src/utils/Constant";
import { Users } from "src/context";
import { useSnackbarContext } from "src/context/SnackbarContext";

const BookingConfirmation = () => {
  const { organization_id, user_id, offer_id, appointment_id, client_id } =
    useParams();
  const [offerDetails, setOfferDetails] = useState([]);
  const { t } = useTranslation();
  const mounted = IsMounted();
  const { mixpanel } = useContext(Users);

  const [appDetails, setAppDetails] = useState({});
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbarContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isProcess, setIsProcess] = useState(false);
  const [coachDetails, setCoachDetails] = useState(null);

  /*** get offer details in db **/
  const getOffer = useCallback(async () => {
    let { data } = await http.get(`/api/general/individual/offer/details`, {
      params: {
        offer_id: offer_id,
        organization_id: organization_id,
        user_id: user_id,
      },
    });
    if (mounted.current) {
      setOfferDetails(data.data);
    }
  }, [organization_id, user_id, offer_id, mounted]);

  /*** get appointment details by client id and appointment id **/
  const getAppointmentDetails = useCallback(async () => {
    http
      .get(`/api/general/individual/appointment/details`, {
        params: {
          appointment_id,
          client_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          if (Boolean(parseInt(data.data?.client_confirmation))) {
            showSnackbar(
              t("individual.booking.confirmation.alert.confirmed_already"),
              "error"
            );
          }
          setAppDetails(data.data);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_id, mounted, client_id]);

  const handleBookingConfirmation = () => {
    let params = new URLSearchParams();
    params.append("appointment_id", appointment_id);
    params.append("client_id", client_id);
    params.append("organization_id", organization_id);
    params.append("user_id", offerDetails?.user_id);

    setIsProcess(true);

    http
      .post(`/api/general/individual/confirm-booking`, params)
      .then((response) => {
        if (response.status === 201) {
          showSnackbar(
            t("individual.booking.confirmation.alert.confirmed_success"),
            "success"
          );
          navigate(
            `/appointment-individual/booking/thank-you?client_id=${client_id}&appointment_id=${appointment_id}&user_id=${user_id}&organization_id=${organization_id}`
          );
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  const getCoachDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/coach-details", {
        params: {
          user_id,
          organization_id: organization_id,
        },
      })
      .then(({ data }) => {
        setCoachDetails(data);
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));
  }, [user_id, organization_id]);

  useEffect(() => {
    mixpanel.track("Booking Confirmation Page", {
      location: window.location.href,
    });

    getOffer();
    getAppointmentDetails();
    getCoachDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOffer, getAppointmentDetails, getCoachDetails]);

  let bookingDetails = appDetails?.booking_info
    ? JSON.parse(appDetails?.booking_info)
    : null;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (Object.keys(appDetails).length === 0) {
    return <PageNotFound />;
  }

  return (
    <Page title={t("booking.confirm.page")}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 30px)",
          flexDirection: "column",
          px: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "508px" },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Mukta",
                fontWeight: 700,
                fontSize: "40px",
                lineHeight: "42px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                color: "#19181B",
              }}
            >
              {t("individual.booking.confirmed.label.confirm_your_appointment")}
            </Typography>

            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "21px",
                textAlign: "center",
                color: "#6E757C",
              }}
            >
              {t(
                "individual.booking.confirmed.label.confirm_your_appointment.description"
              )}{" "}
              <br />
              {t(
                "individual.booking.confirmed.label.confirm_your_appointment.description.continue"
              )}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar
                sx={{
                  width: "80px",
                  height: "80px",

                  ...(!coachDetails?.avatar && {
                    fontSize: "40px",
                    fontWeight: 700,
                    letterSpacing: "-0.4px",
                    backgroundColor: "#7B1FA2",
                  }),
                }}
                alt={coachDetails?.name?.charAt()?.toUpperCase()}
                // src={Constant.imageUrl("coach", coachDetails?.avatar)}
                src={Constant.getFileInStorage("coach", coachDetails?.avatar)}
              >
                {coachDetails?.name?.charAt()?.toUpperCase()}{" "}
              </Avatar>
            </Box>

            <Box
              sx={{
                width: { xs: "100%" },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "440px" },
                }}
              >
                <Box sx={{ mt: "16px" }}>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "center",
                        color: "#6E757C",
                      }}
                    >
                      {t("individual.booking.confirmed.label.you_are_meeting")}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "20px",
                        lineHeight: "26px",
                        textAlign: "center",
                        color: "#19181B",
                        textTransform: "capitalize",
                      }}
                    >
                      {coachDetails?.name}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%" },
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "12px 24px",
                    gap: "20px",
                    border: "1px solid #DFDFE1",
                    borderRadius: "8px",
                    backgroundColor: "#F7F7F7",
                    mt: "32px",
                  }}
                >
                  <Box sx={{ width: "72px" }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "15px",
                        lineHeight: "20px",
                        color: "#6F6D77",
                      }}
                    >
                      {t("individual.booking.confirmed.label.package")}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: "flex", gap: "7px", alignItems: "center" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "15px",
                        lineHeight: "20px",
                        color: "#222128",
                        textTransform: "capitalize",
                      }}
                    >
                      {offerDetails?.name}
                    </Typography>

                    <DescTooltip
                      title={offerDetails?.name}
                      description={offerDetails?.description}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    border: "1px solid #DFDFE1",
                    borderRadius: "8px",
                    backgroundColor: "#F7F7F7",
                    mt: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "12px 24px",
                      gap: "16px",
                      borderBottom: 1,
                      borderColor: "#DFDFE1",
                    }}
                  >
                    <Box sx={{ width: "72px" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#6F6D77",
                        }}
                      >
                        {t("individual.booking.confirmed.label.date")}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#222128",
                        }}
                      >
                        {moment(bookingDetails?.date).format(
                          "dddd, DD MMM YYYY"
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "12px 24px",
                      gap: "16px",
                    }}
                  >
                    <Box sx={{ width: "72px" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#6F6D77",
                        }}
                      >
                        {t("individual.booking.confirmed.label.time")}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#222128",
                        }}
                      >
                        {moment(
                          `${bookingDetails?.date} ${bookingDetails?.time}`
                        ).format("hh:mm A")}{" "}
                        -{" "}
                        {moment(
                          `${bookingDetails?.date} ${bookingDetails?.time}`
                        )
                          .add(offerDetails?.duration, "minutes")
                          .format("hh:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "12px",
                    mt: "40px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Button
                      onClick={() =>
                        navigate(
                          `/appointments/individual/booking/cancel/${organization_id}/${user_id}/${offer_id}/${appointment_id}/${client_id}`
                        )
                      }
                      fullWidth
                      variant="outlined"
                      color={"primary"}
                      sx={{
                        height: "44px",
                        fontSize: "14px",
                        borderRadius: "12px",
                        ...TreffasStyle.buttonHover,
                      }}
                    >
                      {t("booking.button.cancel")}
                    </Button>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Button
                      variant="contained"
                      color={"primary"}
                      fullWidth
                      sx={{
                        height: "44px",
                        fontSize: "14px",
                        borderRadius: "12px",
                        ...TreffasStyle.buttonHover,
                      }}
                      disabled={
                        isProcess ||
                        Boolean(parseInt(appDetails?.client_confirmation))
                      }
                      startIcon={
                        isProcess && (
                          <CircularProgress color="inherit" size={15} />
                        )
                      }
                      onClick={() => handleBookingConfirmation()}
                    >
                      {t("booking.button.confirm")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default BookingConfirmation;
