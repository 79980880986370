import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Logo from "src/components/Logo";
import { Box, Button, IconButton, Link, Avatar, Tooltip } from "@mui/material";
import ClientPortalGuardedRoutes from "src/routes/client-portal-guarded";
import { useNavigate } from "react-router-dom";
import Constant from "src/utils/Constant";
import NoImage from "src/assets/images/no-image.png";
import { Clients } from "src/context";
import ClientPortalDropdownMenu from "./dropdown-menu/client-portal";
import ClientPortalTopbarMobile from "./mobile/client-portal";
import { useTranslation } from "react-i18next";

const ClientPortalTopBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openDP, setOpenDP] = React.useState(false);
  const { t } = useTranslation();
  const [mobileAnchorElSubRoute, setMobileAnchorElSubRoute] =
    React.useState(null);
  const [mobileAnchorElSubRouteKey, setMobileAnchorElSubRouteKey] =
    React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setOpenDP(false);
  };

  const { client } = React.useContext(Clients);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    setOpenDP(true);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: { xs: "#F7F7F7", md: "#fff" },
        borderBottom: { xs: "none", md: "1px solid #EDEDED" },
      }}
      elevation={0}
    >
      <Container
        maxWidth="false"
        sx={(themes) => ({
          py: "0px",
          px: { xs: "10px", md: "40px" },
          mt: { xs: "40px", md: "0px" },
        })}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              width: "200px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Logo />
          </Box>

          {/* mobile */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <ClientPortalTopbarMobile
              handleOpenNavMenu={handleOpenNavMenu}
              anchorElNav={anchorElNav}
              handleCloseNavMenu={handleCloseNavMenu}
              ClientPortalGuardedRoutes={ClientPortalGuardedRoutes}
              navigate={navigate}
              mobileAnchorElSubRouteKey={mobileAnchorElSubRouteKey}
              mobileAnchorElSubRoute={mobileAnchorElSubRoute}
              setMobileAnchorElSubRoute={setMobileAnchorElSubRoute}
              setMobileAnchorElSubRouteKey={setMobileAnchorElSubRouteKey}
            />
          </Box>
          {/* mobile screen end */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {ClientPortalGuardedRoutes.filter((page) => page.show).map(
              (page, key) => {
                let path = page.path.replace("*", "");

                return (
                  <React.Fragment key={key}>
                    <Button
                      conponent={Link}
                      href={page.withSubs ? "#" : path}
                      id={key}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(path);
                      }}
                      sx={{
                        padding: 0,
                        margin: 0,
                        px: "12px",
                        color: window.location.pathname.includes(path)
                          ? "var(--color-orange)"
                          : "#060A2D",
                        textTransform: "inherit",
                        height: "80px",
                        borderBottom:
                          window.location.pathname.includes(path) &&
                          `3px solid var(--color-orange)`,
                        borderRadius: 0,
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                      }}
                    >
                      {t(page.name)}
                    </Button>
                  </React.Fragment>
                );
              }
            )}
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              width: { xs: "46px", md: "200px" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box>
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: "0px",
                    }}
                  >
                    <Avatar
                      sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                          height: "40px",
                          width: "40px",
                        },
                      })}
                      alt={client?.name}
                      src={
                        client?.image
                          ? Constant.getFileInStorage("clients", client?.image)
                          : // Constant.imageUrl("clients", client?.image)
                            NoImage
                      }
                    />
                  </IconButton>
                </Tooltip>

                {/* dropdown menu for web browser */}
                {openDP && (
                  <ClientPortalDropdownMenu
                    anchorElUser={anchorElUser}
                    handleCloseUserMenu={handleCloseUserMenu}
                    setAnchorElUser={setAnchorElUser}
                    openDP={openDP}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ClientPortalTopBar;
